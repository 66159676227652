import axios from "axios";
import Cookies from "universal-cookie";

const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env;
const cookies = new Cookies();

const createAxiosInstance = (baseURL) => {
    const instance = axios.create({
        baseURL,
        headers: {
            //   client_id: REACT_APP_CLIENT_ID,
            Authorization: `Bearer ${cookies.get("access_token")}`,
            user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    });

    instance.interceptors.request.use(
        (req) => {
            const token = cookies.get("access_token");
            if (token) {
                req.headers.Authorization = `Bearer ${token}`;
            }
            return req;
        },
        (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(
        (response) => response,
        (error) => Promise.reject(error)
    );

    return instance;
};

const commonApiCall = createAxiosInstance(REACT_APP_BASE_URL);

//Get Api
export const handleGetApiCall = (apiUrl, payload, requestID = null) =>
    new Promise((resolve, reject) => {
        const url = requestID ? `${apiUrl}/${requestID}` : apiUrl;
        commonApiCall
            .get(url, { params: payload })
            .then((response) => {
                resolve({
                    status: "success",
                    data: response.data,
                    message: response.data.message || "",
                });
            })
            .catch((error) => {
                
                console.log(error)
                reject(error);
            });
    });

//Post Api
export const handlePostApiCall = (apiUrl, payload) =>
    new Promise((resolve, reject) => {
        const url = apiUrl;

        const headers = payload instanceof FormData
            ? { 'Content-Type': 'multipart/form-data' }
            : { 'Content-Type': 'application/json' };

        commonApiCall
            .post(url, payload, { headers })
            .then((response) => {
                resolve({
                    status: "success",
                    data: response.data,
                    message: response.data.message || "",
                });
            })
            .catch((error) => {
                reject(error);
            });
    });

//Put Api
export const handlePutApiCall = (apiUrl, payload, requestParam, requestID = null) =>
    new Promise((resolve, reject) => {
        const url = requestID ? `${apiUrl}/${requestID}` : apiUrl;
        commonApiCall
            .put(url, payload, { params: requestParam })
            .then((response) => {
                resolve({
                    status: "success",
                    data: response.data,
                    message: response.data.message || "",
                });
            })
            .catch((error) => {
                reject(error);
            });
    });

//Patch Api
export const handlePatchApiCall = (apiUrl, payload, requestID = null) =>
    new Promise((resolve, reject) => {
        const url = requestID ? `${apiUrl}/${requestID}` : apiUrl;
        commonApiCall
            .patch(url, payload)
            .then((response) => {
                resolve({
                    status: "success",
                    data: response.data,
                    message: response.data.message || "",
                });
            })
            .catch((error) => {
                reject(error);
            });
    });

//Delete Api
export const handleDeleteApiCall = (apiUrl, payload, requestParam = {}, requestID = null) =>
    new Promise((resolve, reject) => {
        const url = requestID ? `${apiUrl}/${requestID}` : apiUrl;
        commonApiCall
            .delete(url, { data: payload, params: requestParam })
            .then((response) => {
                resolve({
                    status: "success",
                    data: response.data,
                    message: response.data.message || "",
                });
            })
            .catch((error) => {
                reject(error);
            });
    });

export { commonApiCall };