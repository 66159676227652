import { Button, Rating, TextField } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import './Home.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faComment, faStar } from '@fortawesome/free-solid-svg-icons';
import MainContext from '../../context/MainContext';
import { handlePutApiCall } from '../../api/CommonApiCall';
import { updateRatingUrl } from '../../constant/UrlList';
import { OverlayPanel } from 'primereact/overlaypanel';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const OverlayRating = ({ message, overlayCommentRef }) => {

  const { showToastMessage,
    selectedConversation,
    setConversationMessages
  } = useContext(MainContext);
  const opRef = React.useRef();
  const ratingRef = React.useRef();

  const [isEditRating, setIsEditRating] = React.useState(true); // To toggle edit mode
  const [botRating, setBotRating] = React.useState(message?.rating || 0); // Rating state
  const [botComment, setBotComment] = React.useState(message?.comment || "");

  const handleUpdateRating = async (messageId) => {
    if (!botComment?.trim()) {
      showToastMessage("warning", "Please Enter Comment");
      return;
    }
    try {
      let requestData = {
        "rating": botRating,
        "comment": botComment
      }
      let requestParam = {
        "message_id": messageId,
        "conversation_id": selectedConversation
      }
      const apiResponse =
        await handlePutApiCall(updateRatingUrl, requestData, requestParam);
      let updatedData = (apiResponse?.data)

      setConversationMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === parseInt(updatedData.message_id)
            ? { ...message, rating: updatedData.rating, comment: updatedData.comment }
            : message
        )
      );

      // setIsEditRating(false)

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      showToastMessage("error", error.response.data?.message);
    } finally { }
  }

  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  useEffect(()=>{
    if(showPopover){
      setBotRating(message?.rating || 0)
      setBotComment(message?.comment || "")
    }    
  },[showPopover])

  const togglePopover = () => setShowPopover((prev) => !prev);

  return (
    <div className='text-end' ref={overlayCommentRef}>

      <OverlayTrigger
        trigger="click"
        placement={'top'}
        show={showPopover}
        onToggle={togglePopover}
        overlay={
          <Popover ref={popoverRef}
            style={{ position: "absolute", zIndex: 1050 }}
            appendTo={document.body}
            onMouseEnter={(e) => {
              setShowPopover(true);
            }}
            onMouseLeave={(e) => {
              setShowPopover(false);
            }}
          >
            <Popover.Body>

              {!isEditRating && (
                <section className='text-start'>
                  <div className='d-flex align-items-center'>
                    <Rating
                      name="bot-rating"
                      precision={0.5}
                      readOnly
                      value={message?.rating}
                    />
                    <span
                      style={{
                        textTransform: "capitalize",
                        cursor: "pointer",
                        color: "green",
                        fontSize: "13px"
                      }}
                      onClick={() => {
                        setIsEditRating(true)
                      }}
                    >
                      <FontAwesomeIcon icon={faComment} className='mx-1' />{"Comment"}
                    </span>
                  </div>
                  <p className='mb-0'
                    style={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                      marginTop: "6px",
                      fontSize: "13px",
                      color: "#514a4a"
                    }}
                  >
                    {message?.comment || "No comment"}
                  </p>
                </section>)}

              {isEditRating && (
                <section className='text-start'>
                  <Rating
                    name="bot-rating"
                    defaultValue={2.5}
                    precision={0.5}
                    value={botRating}
                    onChange={(event, newValue) => {
                      setBotRating(newValue)
                    }}
                    onChangeActive={(event, newHover) => {
                      // console.log(newHover)
                    }}
                  />
                  <TextField
                    sx={{
                      "& .MuiInputBase-root": {
                        resize: "vertical",
                        overflow: "auto",
                      },
                    }}  
                    multiline
                    rows={2}
                    className='message-area'
                    fullWidth
                    size='small'
                    placeholder="Add the Comment..."
                    value={botComment}
                    onChange={(e) => {
                      setBotComment(e.target.value)
                    }}
                    onKeyUp={(event) => { }}
                  />
                  <div className='text-end'>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      className="start-button mt-2"
                      style={{
                        textTransform: "capitalize"
                      }}
                      onClick={() => {
                        handleUpdateRating(message?.id)
                      }}
                    >
                      <FontAwesomeIcon icon={faComment} className='me-2' />Submit
                    </Button>
                    {/* <Button
                    variant="contained"
                    color="error"
                    size="small"
                    className="start-button mt-2 ms-2"
                    style={{
                      textTransform: "capitalize"
                    }}
                    onClick={() => {
                      setIsEditRating(false)
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} className='me-2' />Close
                  </Button> */}
                  </div>
                </section>
              )}
            </Popover.Body>
          </Popover>
        }
      >
        <div
          ref={ratingRef}
          onClick={(e) => {
            // setIsEditRating(false)
            setShowPopover(true);
          }}
          onMouseEnter={(e) => {
            setShowPopover(true);
          }}
          onMouseLeave={(e) => {
            setShowPopover(false);
          }}
          style={{ display: "inline-block", cursor: "pointer" }}
          className='rating-text'
        >
          {message?.rating}
          <div style={{display: "inline-block"}} className='rating-star-icon'>
            <span>
              <FontAwesomeIcon icon={faStar} className="mx-1" style={{
                fontSize: "14px",
                color: message?.rating ? "yellow" : "white",
              }} />
              <div class="gradient"></div>
            </span>
          
          </div>
        </div>
      </OverlayTrigger>
    </div>
  )
}

export default OverlayRating;