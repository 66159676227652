import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import './MenuBar.scss';
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faHouse, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import MainContext from '../../context/MainContext';
const settings = ['Logout'];

function MenuBar() {

  const { 
    decodedToken
  } = useContext(MainContext);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const cookies = new Cookies();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static"  className='mb-2 dh-top-menubar'>
      <Container maxWidth="xl">
        <Toolbar disableGutters className='d-flex justify-content-between'>
          <div className="app-bar-nav-icon" style={{cursor: "pointer"}}onClick={()=>{
            navigate("/")
          }}>
            <img
                src={require("../../assets/image/dh-logo-dark.png")}
                alt=""
                className="dh-nav-logo mobile"
            />
            <img
                src={require("../../assets/image/dh-logo-dark.png")}
                alt=""
                className="dh-nav-logo desktop"
            /> 
            Deep Holistics
          </div>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Vinish" src={require("../../assets/image/avatar-1.jpg")} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => ( */}
              {decodedToken?.is_beta && (
                <>
                  <MenuItem onClick={() => {
                    handleCloseUserMenu()
                    navigate("/")
                  }}>
                    <Typography sx={{ textAlign: 'center' }}><FontAwesomeIcon icon={faHouse} className="me-2" />{"Home"}</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    handleCloseUserMenu()
                    navigate("/prompt")
                  }}>
                    <Typography sx={{ textAlign: 'center' }}><FontAwesomeIcon icon={faGear} className="me-2" />{"Customize"}</Typography>
                  </MenuItem>
                </>
              )}
                <MenuItem onClick={() => {
                  handleCloseUserMenu()
                  cookies.remove("access_token");
                  navigate("/login")
                }}>
                  <Typography sx={{ textAlign: 'center' }}><FontAwesomeIcon icon={faRightFromBracket} className="me-2"/>{"Logout"}</Typography>
                </MenuItem>
              {/* ))} */}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default MenuBar;
