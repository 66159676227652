export const authRegisterUrl = "/users/register";
export const authLoginUrl = "/users/login";
export const authLogoutUrl = "/users/logout";
export const authMobileSendOTP = "/users/mobilelogin";
export const authMobileVerifyOTP = "/users/mobile/otp";

export const getConversationsListUrl = "/conversations";
export const startConversationsUrl = "/conversations/start_conversation";
export const deleteConversationsUrl = "/conversations/delete_conversation";
export const updateConversationTitle ="/conversations/edit_title"
export const sendMessageUrl = "/messages/send_message";
export const sendMessageFormDataUrl = "/messages/send_file";
export const getConversationsUrl = "/conversations";

export const updateRatingUrl = "/messages/rate_and_comment";

export const getUserPromptUrl = "/user_prompt/prompt";
export const updateUserPromptUrl = "/user_prompt/prompt/update";

export const getUserMetaDataUrl = "/user_prompt/metadata";
export const updateUserMetaDataUrl = "/user_prompt/metadata/update";
