import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Cookies from "universal-cookie";
import Home from './components/home/Home';
import AuthMobileLogin from './components/auth/AuthMobileLogin';
import { useContext, useEffect } from 'react';
import MainContext from "./context/MainContext";
import Prompt from './components/home/Prompt';

const PrivateRoute = ({ children }) => {
  const cookies = new Cookies();
  const dashBoardToken = cookies.get("access_token");

  const isValidUser = Boolean(dashBoardToken);

  return isValidUser ? children : <Navigate to="/login" />;
  // return children;
};

const PublicRoute = ({ children }) => {
  const cookies = new Cookies();
  const webAccessToken = cookies.get("access_token");

  const isValidUser = Boolean(webAccessToken);

  return !isValidUser ? children : <Navigate to="/" />;
};

function App() {

  // const navigate = useNavigate();
  const { setAccessToken } = useContext(MainContext)

  /* Webview Intergration */
  const isAndroid = /android/.test(navigator.userAgent.toLowerCase());
  const isIOS = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());

  //Getting Data from Native
  window.loadAccessToken = (token, timeZone) => {
    console.log("Access Token Received")
    setAccessToken(token);
  }

  //Global Access - Declaration
  useEffect(() => {
    // window.loadAccessToken = loadAccessToken;
  }, []);

  //Send Response
  const sendResponseToNative = () => {

    /* Integration Update */

    if (isAndroid) {

      window.sendResponseToAndroidWebView();

    } else if (isIOS) {

      const webViewRef = window.webkit?.messageHandlers.goal;
      if (webViewRef) {
        webViewRef.postMessage({
          status: "ok",
        });
      } else {
        console.error("WebView not found");
      }

    } else {
      console.log("OS Platform not Found");
    }
  };

  //Android-Integration
  window.sendResponseToAndroidWebView = () => {
    window.Android.unlockPremium("true");
    return "true";
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <PublicRoute>
                {/* <AuthForm /> */}
                <AuthMobileLogin />
              </PublicRoute>
              }
          />

          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
            exact={true}
          />

          <Route
            path="/prompt"
            element={
              <PrivateRoute>
                <Prompt />
              </PrivateRoute>
            }
            exact={true}
          />

        </Routes>
      </Router>
    </div >
  );
}

export default App;
