import axios from "axios";
import Cookies from "universal-cookie";

const { REACT_APP_BASE_URL, REACT_APP_CLIENT_ID } = process.env;
const cookies = new Cookies();

const createAxiosInstance = (baseURL) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
    //   client_id: REACT_APP_CLIENT_ID,
      Authorization: `Bearer ${cookies.get("access_token")}`,
      user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
};

const sessionLoginAPI = createAxiosInstance(REACT_APP_BASE_URL);

const applyAccessTokenInterceptor = (apiInstance) => {
  apiInstance.interceptors.request.use(
    (req) => {
      const token = cookies.get("access_token");
      if (token) {
        req.headers["Authorization"] = `Bearer ${token}`; // Set Access Token
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  apiInstance.interceptors.response.use(
    (req) => {
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

applyAccessTokenInterceptor(sessionLoginAPI);

export const authRegister = (requestData) =>
  new Promise((resolve, reject) => {
    sessionLoginAPI
      .post(`/users/register`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data });
      })
      .catch((error) => {
        console.info(error);
        reject(error);
      });
  });

export const authLogin = (requestData) =>
  new Promise((resolve, reject) => {
    sessionLoginAPI
      .post(`/users/login`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data });
      })
      .catch((error) => {
        console.info(error);
        reject(error);
      });
  });

export const authLogout = (requestData) =>
  new Promise((resolve, reject) => {
    sessionLoginAPI
      .post(`/users/logout`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data });
      })
      .catch((error) => {
        console.info(error);
        reject(error.response?.data);
      });
  });

export const startConversations = (requestData) =>
  new Promise((resolve, reject) => {
    sessionLoginAPI
      .post(`/conversations/start_conversation`, requestData)
      .then((response) => {
        resolve({ status: "success", data: response.data });
      })
      .catch((error) => {
        console.info(error);
        reject(error.response?.data);
      });
  });

export const sendMessage = (requestData) =>
    new Promise((resolve, reject) => {
      sessionLoginAPI
        .post(`/messages/send_message`, requestData)
        .then((response) => {
          resolve({ status: "success", data: response.data });
        })
        .catch((error) => {
          console.info(error);
          reject(error.response?.data);
        });
    });

export const getConversationsList = (requestData) =>
  new Promise((resolve, reject) => {
    sessionLoginAPI
      .get(`/conversations`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data });
      })
      .catch((error) => {
        console.info(error);
        reject(error.response?.data);
      });
  });

export const getConversations = (requestData, requestId) =>
  new Promise((resolve, reject) => {
    sessionLoginAPI
      .get(`/conversations/${requestId}`, { params: requestData })
      .then((response) => {
        resolve({ status: "success", data: response.data });
      })
      .catch((error) => {
        console.info(error);
        reject(error.response?.data);
      });
  });



export { sessionLoginAPI };
