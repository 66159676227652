import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'primereact/resources/themes/saga-blue/theme.css';  // or another theme
import 'primereact/resources/primereact.min.css';          // PrimeReact core CSS
import "primereact/resources/themes/lara-light-cyan/theme.css";
// import 'react-intl-tel-input/dist/main.css';

import 'primeicons/primeicons.css';
import "./index.css";
import App from './App';
import { MainContextProvider } from './context/MainContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MainContextProvider>
      <App />
    </MainContextProvider>
  </React.StrictMode>
);

