export function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();

    // Adjust the date to Indian Standard Time (UTC +5:30)
    const ISTOffset = 5.5 * 60 * 60 * 1000; // IST is UTC +5:30 in milliseconds
    const dateInIST = new Date(date.getTime() + ISTOffset);

    const secondsDiff = Math.floor((now - date) / 1000);
    const minutesDiff = Math.floor(secondsDiff / 60);
    const hoursDiff = Math.floor(minutesDiff / 60);
    const daysDiff = Math.floor(hoursDiff / 24);

    if (secondsDiff < 60) {
        return `${secondsDiff} ${secondsDiff === 1 ? 'second' : 'seconds'} ago`;
    } else if (minutesDiff < 60) {
        return `${minutesDiff} ${minutesDiff === 1 ? 'minute' : 'minutes'} ago`;
    } else if (hoursDiff < 24) {
        return dateInIST.toLocaleTimeString('en-IN', { 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: true
        }); // "02:15 PM"
    } else {
        return dateInIST.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }); // "29 Oct 2024, 02:15 PM"
    }
}


export const renderText = (text) => {
    const regex = /\*\*(.*?)\*\*/g;  // Regex to match text wrapped with **
    const parts = [];
    let lastIndex = 0;

    let match;
    while ((match = regex.exec(text)) !== null) {
      // Add the part before the bolded text
      if (match.index > lastIndex) {
        parts.push(<span key={lastIndex}>{text.slice(lastIndex, match.index)}</span>);
      }
      // Add the bolded part
      parts.push(<strong key={match.index}>{match[1]}</strong>);
      lastIndex = regex.lastIndex;
    }

    // Add the remaining part of the text after the last match
    if (lastIndex < text.length) {
      parts.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>);
    }

    return parts;
  };
