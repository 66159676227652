import React, { memo, useContext, useEffect, useState } from 'react'
import MenuBar from './MenuBar'
import { Button, Grid, TextField } from '@mui/material'
import { handleGetApiCall, handlePutApiCall } from '../../api/CommonApiCall';
import { getConversationsUrl, getUserMetaDataUrl, getUserPromptUrl, updateUserMetaDataUrl, updateUserPromptUrl } from '../../constant/UrlList';
import MainContext from '../../context/MainContext';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'primereact/tooltip';

const Prompt = () => {

    const [isConversationLoading, setIsConversationLoading] = useState(false);

    const [promptInput, setPromptInput] = useState("");
    const [metaUserReport, setMetaUserReport] = useState("");
    const [metaParamTracker, setMetaParamTracker] = useState("");
    const [metaPathwayAnalyser, setMetaPathwayAnalyser] = useState("");
    const [metaUserStats, setMetaUserStats] = useState("");

    const { showToastMessage } = useContext(MainContext);

    useEffect(()=>{
        handleGetPrompt()
        handleGeteMetaInfo()
    },[])

    const ChatConversationLoader = () => {
        return (
            <div className="chat-conversation-loader">
                <img src={require("../../assets/image/preloader-2.gif")} alt="" />
            </div>
        );
    };

    const handleGetPrompt = async () => {
        try {
            let requestData = {}
            setIsConversationLoading(true)
            const getResponse =
                await handleGetApiCall(getUserPromptUrl, requestData, null);
                setPromptInput(getResponse?.data?.prompt || "");
        } catch (error) {
            console.error(
                "Error fetching data:",
                error.response ? error.response.data : error.message
            );
            showToastMessage("error", error.response.data?.message);
        } finally {
            setIsConversationLoading(false);
        }
    }

    const handleUpdatePrompt = async () => {
        try {
            let requestData = {
                "prompt": promptInput
            }
            setIsConversationLoading(true)
            const getResponse =
                await handlePutApiCall(updateUserPromptUrl, requestData, {}, null);
                showToastMessage("success", "System Prompt update successfully.");
        } catch (error) {
            console.error(
                "Error fetching data:",
                error.response ? error.response.data : error.message
            );
            showToastMessage("error", error.response.data?.message);
        } finally {
            setIsConversationLoading(false);
        }
    }   

    const handleGeteMetaInfo = async (id) => {
        try {
            let requestData = {}
            setIsConversationLoading(true)
            const getResponse =
                await handleGetApiCall(getUserMetaDataUrl, requestData, id);
                
            let metaData = getResponse?.data?.metadata;
            
            setMetaUserReport(metaData.find(item => item.identifier === "user_report")?.description || "")
            setMetaParamTracker(metaData.find(item => item.identifier === "param_tracker")?.description || "")
            setMetaPathwayAnalyser(metaData.find(item => item.identifier === "pathway_analyser")?.description || "")
            setMetaUserStats(metaData.find(item => item.identifier === "user_stats")?.description || "")

        } catch (error) {
            console.error(
                "Error fetching data:",
                error.response ? error.response.data : error.message
            );
            showToastMessage("error", error.response.data?.message);
        } finally {
            setIsConversationLoading(false);
        }
    }
    
    const handleUpdateMetaInfo = async (metaRequestData) => {
        try {
            let requestData = metaRequestData;

            setIsConversationLoading(true)
            const getResponse =
                await handlePutApiCall(updateUserMetaDataUrl, requestData, {}, null);
                showToastMessage("success", "Meta Info update successfully.");
        } catch (error) {
            console.error(
                "Error fetching data:",
                error.response ? error.response.data : error.message
            );
            showToastMessage("error", error.response.data?.message);
        } finally {
            setIsConversationLoading(false);
        }
    }   


    const MetaInput = memo(({ label, placeholder, value, onChangeState, buttonText, onSubmit }) => {
        return (
            <Grid item md={12} sm={12} xs={12} className="">
                <Card>
                    <Card.Header>
                        {label}
                    </Card.Header>
                    <Card.Body className='mt-2'>
                        <TextField
                            sx={{
                                "& .MuiInputBase-root": {
                                    resize: "vertical",
                                    overflow: "auto",
                                },
                            }}  
                            size={"small"}
                            fullWidth
                            placeholder={placeholder}
                            label={label}
                            type={"text"}
                            multiline
                            rows={6}
                            value={value}
                            onChange={(e) => {
                                onChangeState(e.target?.value);
                            }}
                        />
                        <div className="text-end">
                            <Button
                                variant="contained"
                                size={"small"}
                                style={{ textTransform: "capitalize" }}
                                className="mt-2"
                                onClick={onSubmit}
                            >
                                {buttonText}
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Grid>
        );
    });    

    return (
        <div>
            {isConversationLoading && (<ChatConversationLoader />)}
            <MenuBar />
            <section className='main-container'>
                <Grid container spacing={2} className='prompt-section-container'>
                    <Grid item md={12} sm={12} xs={12} className="">
                        <Card>
                            <Card.Header style={{
                                background: "#d1d1d1"
                            }}>
                                System Prompt
                            </Card.Header>
                            <Card.Body className='mt-3'>
                                <TextField
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            resize: "vertical",
                                            overflow: "auto",
                                        },
                                    }}                            
                                    size={"small"}
                                    fullWidth
                                    placeholder='Enter the Prompt'
                                    label="Prompt"
                                    type={"text"}
                                    name="prompt_text"
                                    multiline
                                    rows={6}
                                    value={promptInput}
                                    onChange={(e) => {
                                        setPromptInput(e.target?.value)
                                    }}
                                />
                                <div className='text-end d-flex justify-content-end align-items-center'>
                                    <div>
                                        <Tooltip target=".custom-tooltip-btn" 
                                            style={{width: "350px"}}
                                            tooltipOptions={{ position: 'bottom', showDelay: 1000, hideDelay: 300 }}>
                                            <div className='prompt-suggestion-tip'>
                                                <p className='mb-2'>Please use these variables for adding prompts between double curly braces: {"{{user_name}}"}</p>
                                                <p>User Name: {"{{user_name}}"}</p>
                                                <p>Age: {"{{age}}"}</p>
                                                <p>Height: {"{{height}}"}</p>
                                                <p>Weight: {"{{weight}}"}</p>
                                                <p>Conditions: {"{{conditions}}"}</p>
                                            </div>
                                        </Tooltip>
                                        <FontAwesomeIcon 
                                            icon={faInfoCircle} 
                                            className='custom-tooltip-btn me-2 mt-2' 
                                            tooltipOptions={{ position: 'bottom', showDelay: 1000, hideDelay: 300 }}
                                            style={{
                                                fontSize: "28px",
                                                color: "green",
                                                cursor: "pointer"
                                            }} 
                                            
                                        />
                                    </div>
                                    <Button
                                        variant="contained"
                                        size={"small"}
                                        style={{ textTransform: "capitalize" }}
                                        className='mt-2'
                                        onClick={()=>{
                                            handleUpdatePrompt()
                                        }}
                                    >
                                        Submit Prompt
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Grid>
                    
                    <Grid item md={12} sm={12} xs={12} className="">
                        <Card>
                            <Card.Header style={{
                                background: "#d1d1d1"
                            }}>
                                Meta Info
                            </Card.Header>

                            <Card.Body className='mt-2'>
                                <TextField
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            resize: "vertical",
                                            overflow: "auto",
                                        },
                                    }}  
                                    size={"small"}
                                    fullWidth
                                    placeholder='Enter the Prompt'
                                    label="User Report"
                                    type={"text"}
                                    name="prompt_text"
                                    multiline
                                    rows={6}
                                    value={metaUserReport}
                                    onChange={(e) => {
                                        setMetaUserReport(e.target?.value)
                                    }}
                                />
                                <div className='text-end'>
                                    <Button
                                        variant="contained"
                                        size={"small"}
                                        style={{ textTransform: "capitalize" }}
                                        className='mt-2'
                                        onClick={() =>
                                            handleUpdateMetaInfo({
                                                type: "query_engine",
                                                identifier: "user_report",
                                                description: metaUserReport,
                                            })
                                        }
                                    >
                                        Submit User Report
                                    </Button>
                                </div>
                            </Card.Body>

                            <Card.Body className='mt-2'>
                                <TextField
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            resize: "vertical",
                                            overflow: "auto",
                                        },
                                    }}  
                                    size={"small"}
                                    fullWidth
                                    placeholder='Enter the Param Tracker'
                                    label="Param Tracker"
                                    type={"text"}
                                    name="Param Tracker"
                                    multiline
                                    rows={6}
                                    value={metaParamTracker}
                                    onChange={(e) => {
                                        setMetaParamTracker(e.target?.value)
                                    }}
                                />
                                <div className='text-end'>
                                    <Button
                                        variant="contained"
                                        size={"small"}
                                        style={{ textTransform: "capitalize" }}
                                        className='mt-2'
                                        onClick={() =>
                                            handleUpdateMetaInfo({
                                                "type": "query_engine",
                                                "identifier": "param_tracker",
                                                "description": metaParamTracker
                                            })
                                        }
                                    >
                                        Submit Param Tracker
                                    </Button>
                                </div>
                            </Card.Body>

                            <Card.Body className='mt-2'>
                                <TextField
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            resize: "vertical",
                                            overflow: "auto",
                                        },
                                    }}  
                                    size={"small"}
                                    fullWidth
                                    placeholder='Enter the Pathway Analyser'
                                    label="Pathway Analyser"
                                    type={"text"}
                                    name="Pathway Analyser"
                                    multiline
                                    rows={6}
                                    value={metaPathwayAnalyser}
                                    onChange={(e) => {
                                        setMetaPathwayAnalyser(e.target?.value)
                                    }}
                                />
                                <div className='text-end'>
                                    <Button
                                        variant="contained"
                                        size={"small"}
                                        style={{ textTransform: "capitalize" }}
                                        className='mt-2'
                                        onClick={() =>
                                            handleUpdateMetaInfo({
                                                "type": "query_engine",
                                                "identifier": "pathway_analyser",
                                                "description": metaPathwayAnalyser
                                            })
                                        }
                                    >
                                        Submit Pathway Analyser
                                    </Button>
                                </div>
                            </Card.Body>

                            <Card.Body className='mt-2'>
                                <TextField
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            resize: "vertical",
                                            overflow: "auto",
                                        },
                                    }}  
                                    size={"small"}
                                    fullWidth
                                    placeholder='Enter the User Stats'
                                    label="User Stats"
                                    type={"text"}
                                    name="User Stats"
                                    multiline
                                    rows={6}
                                    value={metaUserStats}
                                    onChange={(e) => {
                                        setMetaUserStats(e.target?.value)
                                    }}
                                />
                                <div className='text-end'>
                                    <Button
                                        variant="contained"
                                        size={"small"}
                                        style={{ textTransform: "capitalize" }}
                                        className='mt-2'
                                        onClick={() =>
                                            handleUpdateMetaInfo({
                                                "type": "query_engine",
                                                "identifier": "user_stats",
                                                "description": metaUserStats
                                            })
                                        }
                                    >
                                        Submit User Stats
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Grid>
                </Grid>
            </section>
        </div>
    )
}

export default Prompt