import React, { useContext, useState } from "react";
import "./Auth.scss";
import {
    Button
} from "@mui/material";
import MainContext from "../../context/MainContext";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { authLogin, authRegister } from "../../api/AuthApi";
import { Password } from 'primereact/password';
import { handlePostApiCall } from "../../api/CommonApiCall";
import { authLoginUrl, authLogoutUrl, authMobileSendOTP, authMobileVerifyOTP, authRegisterUrl } from "../../constant/UrlList";
// import IntlTelInput from 'react-intl-tel-input';
import { InputOtp } from 'primereact/inputotp';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const AuthMobileLogin = () => {
    const [isLoginActive, setIsLoginActive] = useState(true);

    const cookies = new Cookies();
    const navigate = useNavigate();
    
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const { showToastMessage, accessToken, setAccessToken,
        isLoginPreLoading, setIsLoginPreLoading } = useContext(MainContext)

    const handleChange = (e, callback) => {
        callback(e.target.value);
    };

    const validateEmail = (inputText) => {
        const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return mailformat.test(inputText);
    };

    const handleSubmit = async () => {

        let requestData = {
            username: email,
            password: password,
        };
        
        if(email?.trim() === "" || password?.trim() === ""){
            showToastMessage("error", "Please enter valid username and password")
            return null;
        }

        // if (validateEmail(email)) {
          setIsLoginPreLoading(true);
          try {
            // const responseDashboardLogin = await authLogin(requestData);
            const responseDashboardLogin = await handlePostApiCall(authLoginUrl, requestData);
            console.log(responseDashboardLogin)
            let responseToken = responseDashboardLogin?.data?.access_token;

            cookies.set("access_token", responseToken, {
                path: "/",
                maxAge: 604800,
            });

            setAccessToken(responseToken);
            setIsLoginPreLoading(false);
            navigate("/");

          } catch (error) {
            console.error(
              "Error fetching data:",
              error.response ? error.response.data : error.message
            );
            showToastMessage("error", error.response.data?.message);
          } finally {
            setIsLoginPreLoading(false);
          }
        // } else {
        //   showToastMessage("warning", "Please enter valid email-address")
        // }
    };

    const handleRegister = async () => {

        let requestData = {
            username: email,
            password: password,
        };

        // if (validateEmail(email)) {
          setIsLoginPreLoading(true);
          try {
            // const responseDashboardLogin = await authRegister(requestData);
            const responseDashboardLogin = await handlePostApiCall(authRegisterUrl, requestData);
            let responseToken = responseDashboardLogin?.data?.access_token;

            setIsLoginPreLoading(false);
            setIsLoginActive(true)

          } catch (error) {
            console.error(
              "Error fetching data:",
              error.response.data
            );
            showToastMessage("error", error.response.data?.message);
          } finally {
            setIsLoginPreLoading(false);
          }
        // } else {
        //   showToastMessage("warning", "Please enter valid email-address")
        // }
    };

    const [countryCode, setCountryCode] = useState("")
    const [phoneNumberValue, setPhoneNumberValue] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [isOTPSend, setIsOTPSend] = useState(false)

    const [otp, setOtp] = useState();


    const handlePhoneNumberChange = (status, value, countryData) => {
        setPhoneNumber(value.replace(`+${countryData.dialCode}`, '').trim());
        setCountryCode(countryData.dialCode);   
    };

    const hendleSendOTP = async () => {
        let requestData = {
            "mobile": phoneNumber,
            "country_code": countryCode,
            "mobile_type": "ios",
            "app_version": "1.0.0",
            "service": "SC",
            "mobile_details": {}
        };        

        if (countryCode?.trim() === "" || phoneNumber?.trim() === "") {
            showToastMessage("error", "Please enter valid mobile number")
            return null;
        }

        setIsLoginPreLoading(true);
        try {
            const responseDashboardLogin = await handlePostApiCall(authMobileSendOTP, requestData);
            showToastMessage("success", "OTP Sent successfully");
            setIsOTPSend(true);
            setIsLoginPreLoading(false);

        } catch (error) {
            console.error(
                "Error fetching data:",
                error.response ? error.response.data : error.message
            );
            showToastMessage("error", error.response.data?.message);
        } finally {
            setIsLoginPreLoading(false);
        }
    }

    const hendleVerifyOTP = async () => {
        let requestData = {
            "mobile": phoneNumber,
            "country_code": countryCode,
            "otp": otp,
            "service": "SC",
            "app_version": "1.0.0",
            "mobile_type": "ios",
            "mobile_details": {
                "device": "Test Device",
                "model_no": "8T",
                "manufacturer": "OnePlus",
                "os_build_version": "10.0",
                "mobile_unique_id": "123123123",
                "apps_used": [
                    {
                        "app_id": "com.example.app",
                        "used": true
                    }
                ]
            }
        };

        if (countryCode?.trim() === "" || phoneNumber?.trim() === "") {
            showToastMessage("error", "Please enter valid mobile number")
            return null;
        }

        setIsLoginPreLoading(true);
        try {
            const responseDashboardLogin = await handlePostApiCall(authMobileVerifyOTP, requestData);
            // showToastMessage("success", "OTP Sent successfully");
            setIsOTPSend(false);

            console.log(responseDashboardLogin)
            let responseToken = responseDashboardLogin?.data?.access_token;

            cookies.set("access_token", responseToken, {
                path: "/",
                maxAge: 604800,
            });

            setAccessToken(responseToken);
            setIsLoginPreLoading(false);
            navigate("/");

        } catch (error) {
            console.error(
                "Error fetching data:",
                error.response ? error.response.data : error.message
            );
            showToastMessage("error", error.response.data?.message);
        } finally {
            setIsLoginPreLoading(false);
        }
    }

    return (
        <section className="login-container">
            
            <div className="wrapper">
                <div className="title-text">
                    <img
                        src={require("../../assets/image/dh-logo-dark.png")}
                        alt=""
                        className="login-logo"
                    />
                    <div className={`title login`}>
                        DH Chatbot {isLoginActive ? "Login" : "Register"}
                    </div>
                </div>
                <div className="form-container">
                    {isLoginActive && (
                        <div className="form-inner" style={{ marginLeft: isLoginActive ? "0%" : "0%" }}>
                            <section className="login login-form" style={{ marginLeft: isLoginActive ? "0%" : "-50%" }}>
                                
                                
                                <div className="field">
                                    <PhoneInput
                                        country={'in'}
                                        value={phoneNumberValue}
                                        enableSearch
                                        countryCodeEditable={false}
                                        preferredCountries={['in', 'sg']}
                                        defaultCountry="in"
                                        onChange={(value, country) => {
                                            setCountryCode(country.dialCode);
                                            setPhoneNumber(value.replace(`${country.dialCode}`, ''));
                                            setPhoneNumberValue(value)
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                hendleSendOTP();
                                            }
                                        }}
                                        // onChange={(phone) => {
                                        //     setPhoneNumber(phone);
                                        // }}
                                    />
                                    {/* <IntlTelInput
                                        preferredCountries={['in', 'sg']}
                                        defaultCountry="in"
                                        separateDialCode
                                        allowDropdown
                                        countrySearch={true}
                                        placeholder="99999 99999"
                                        disabled={isOTPSend}
                                        onPhoneNumberChange={handlePhoneNumberChange}
                                    /> */}
                                </div>

                                {!isOTPSend && (
                                    <div className="">
                                        <Button
                                            variant="contained"
                                            color="success"
                                            size="small"
                                            className="login-submit-button"
                                            onClick={hendleSendOTP}
                                        >
                                            Send OTP
                                        </Button>
                                    </div>
                                )}

                                {isOTPSend && (
                                    <>
                                        <InputOtp
                                            value={otp}
                                            onChange={(e) => setOtp(e.value)}
                                            onKeyDown={(e) => {
                                                console.log("test")
                                                if (e.key === 'Enter') {
                                                    hendleVerifyOTP();
                                                }
                                            }}
                                            integerOnly
                                            length={4}
                                        />
                                        <div className="">
                                            <Button
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                className="login-submit-button"
                                                onClick={hendleVerifyOTP}
                                            >
                                                Verify OTP
                                            </Button>
                                        </div>
                                    </>
                                )}


                                {/* <div className="field">
                                    <input 
                                        type="text" 
                                        value={email} 
                                        placeholder="Username" 
                                        required 
                                        onChange={(e) => handleChange(e, setEmail)} 
                                    />
                                </div>
                                <div className="field">
                                    <Password
                                        value={password}
                                        placeholder="Password"
                                        onChange={(e) => handleChange(e, setPassword)}
                                        onKeyUp={(event) => {
                                            if (event.key === "Enter") {
                                                handleSubmit();
                                            }
                                        }}
                                        toggleMask
                                        feedback={false}
                                    />
                                </div>

                                <div className="">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="small"
                                        className="login-submit-button"
                                        onClick={handleSubmit}
                                    >
                                        Login
                                    </Button>
                                    <p className="register-text" onClick={() => {
                                        setIsLoginActive(false)
                                    }}>Register New Account</p>
                                </div> */}

                            </section>
                        </div>
                    )}

                    {!isLoginActive && (
                        <div className="form-inner" style={{ marginLeft: !isLoginActive ? "0%" : "0%" }}>
                            <section className="login login-form" style={{ marginLeft: !isLoginActive ? "0%" : "-50%" }}>

                               
                                <div className="field">
                                    <input type="text" placeholder="Username" value={email} required onChange={(e) => handleChange(e, setEmail)} />
                                </div>
                                
                                <div className="field">
                                    <Password
                                        value={password}
                                        onChange={(e) => handleChange(e, setPassword)}
                                        toggleMask
                                        placeholder="Enter Password"
                                        feedback={false}
                                    />
                                </div>

                                <div className="field">
                                    <Password
                                        value={confirmPassword}
                                        onChange={(e) => handleChange(e, setConfirmPassword)}
                                        toggleMask
                                        placeholder="Confirm Password"
                                        feedback={false}
                                        onKeyUp={(event) => {
                                            if (event.key === "Enter") {
                                                handleRegister();
                                            }
                                        }}
                                    />
                                </div>

                                <div className="">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="small"
                                        disabled={password !== confirmPassword}
                                        className="login-submit-button"
                                        onClick={handleRegister}
                                    >
                                        Register
                                    </Button>
                                    <p className="register-text" onClick={() => {
                                        setIsLoginActive(true)
                                    }}>Already have an Account</p>
                                    {/* <input type="submit" value="Login" /> */}
                                </div>

                            </section>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default AuthMobileLogin;
