import { Box, Button, Fab, Grid, IconButton, InputAdornment, Menu, MenuItem, Rating, TextField, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import MenuBar from './MenuBar';
import { Card, Modal } from "react-bootstrap";
import './Home.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCheck, faClock, faClose, faDownLong, faEllipsisVertical, faExclamation, faFile, faHeadphones, faImage, faMessage, faPaperclip, faPaperPlane, faPenToSquare, faSearch, faStar, faTrash, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
// import { getConversations, sendMessage, startConversations } from '../../api/AuthApi';
import MainContext from '../../context/MainContext';
// import debounce from 'lodash.debounce';
// import io from 'socket.io-client';
import { handleGetApiCall, handlePostApiCall, handleDeleteApiCall, handlePatchApiCall } from '../../api/CommonApiCall';
import { deleteConversationsUrl, getConversationsUrl, sendMessageFormDataUrl, sendMessageUrl, startConversationsUrl, updateConversationTitle } from '../../constant/UrlList';
import { formatTimestamp, renderText } from '../common/CommonFunctions';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button as PrimeButton } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
// const webSocket_Url = 'http://localhost:5000';
import '@react-pdf-viewer/core/lib/styles/index.css';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';
import OverlayRating from './OverlayRating';
import Swal from "sweetalert2";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;


const Home = () => {
  const [isInitialView, setIsInitialView] = useState(true)
  const [isMessagePreLoading, setIsMessagePreLoading] = useState(false);
  const [isConversationLoading, setIsConversationLoading] = useState(false);

  const [isShowScrollDown, setIsShowScrollDown] = useState(false);

  const [isEditTitle, setIsEditTitle] = useState(false);
  const [conversationTitleInput, setConversationTitleInput] = useState("");

  const [selectedConversationObj, setSelectedConversationObj] = useState([])

  const { showToastMessage, 
          setIsLoginPreLoading,
          selectedConversation, 
          setSelectedConversation, 
          conversationsList,
          setConversationsList,
          conversationMessages, 
          setConversationMessages, 
          getConversationsListCall,
          hasMore,
          setConversationPageNo, loading,
          decodedToken,
          langfuseBaseUrl
        } = useContext(MainContext);

  /* Websocket */

  // useEffect(() => {
  //   const socket = io(webSocket_Url);

  //   socket.on('server_message', (data) => {
  //     console.log('Message from server:', data);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  // const sendBotMessage = () => {
  //   const socket = io(webSocket_Url);
  //   socket.emit('client_message', 'Hello from the React client!');
  // };

  /* End - Websocket  */

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (selectedConversation === 0) { setIsInitialView(true); }
  }, [])

  useEffect(() => {
    const conversationObject = conversationsList?.find(item => item?.id === selectedConversation);
    setSelectedConversationObj(conversationObject)
  }, [selectedConversation])

  useEffect(()=>{
    setSelectedConversation(null);
    getConversationsListCall(false)
  },[])

  const [textMessage, setTextMessage] = useState("");

  /* Function */
  const listContainerRef = useRef(null);
  const messageContainerRef = useRef(null);

  const listScrollToBottom = () => {
    const container = listContainerRef?.current;
    container?.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth',
    });
  };

  const messageScrollToBottom = () => {
    const container = messageContainerRef?.current;
    container?.scrollTo({
      top: container.scrollHeight,
      behavior: 'smooth',
    });
  };

  /* ListSection Scroll */
  const observer = useRef();

  const lastItemRef = (node) => {
    if (loading) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setConversationPageNo((prevPage) => prevPage + 1);
      }
    });

    if (node) observer.current.observe(node);
  };

  /* End - ListSection Scroll */

  // select conversation click
  const handleSelectConversation = async (id) => {
    if(selectedConversation === id){
      return null;
    }
    setSelectedConversation(id);
    setConversationMessages([])

    setIsInitialView(false)
    setIsEditTitle(false)
    try {
      let requestData = {
        "page": 1,
        "per_page": 40
      }
      setMessageRequestParamPage(1)

      setIsConversationLoading(true)

      const getConversationMsgResponse =
        await handleGetApiCall(getConversationsUrl, requestData, id);
      let sendConversationsMessageDataResponse = getConversationMsgResponse?.data?.messages;
      setMessageRequestParamPageTotal(getConversationMsgResponse?.data?.pages)
      setConversationMessages((prevConversations) => [
        // ...prevConversations,
        ...sendConversationsMessageDataResponse?.reverse()
      ]);
      

      setTimeout(() => {
        messageScrollToBottom();
      }, 300)

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      showToastMessage("error", error.response.data?.message);
    } finally {
        setIsConversationLoading(false);     
    }

  }

  // select conversation click
  const handleSetInitialView = () => {
    setIsInitialView(false);
    startConversationCall()
  }

  // start conversation call
  const startConversationCall = async () => {
    setIsLoginPreLoading(true);
    try {
      let requestData = {
        "message": "Hello, bot!"
      }
      // const startConversationResponse = await startConversations(requestData);
      const startConversationResponse =
        await handlePostApiCall(startConversationsUrl, requestData);
      let sendConversationsMessageDataResponse = startConversationResponse?.data?.messages;
      setSelectedConversation(startConversationResponse?.data?.conversation_id)

      setConversationMessages((prevConversations) => [
        // ...prevConversations,
        ...sendConversationsMessageDataResponse
      ]);

      let listDiff = conversationsList?.length % 10;

      if(listDiff === 0){
        setConversationPageNo((prevPage) => prevPage + 1)
      }else{
        getConversationsListCall(false)
      }
      
      setTimeout(() => {
        listScrollToBottom();
      }, 300)
      
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      showToastMessage("error", error.response.data?.message);
    } finally {
      setTimeout(() => {
        setIsLoginPreLoading(false);
      }, 100)
    }
  };

  // send conversation message
  const sendConversationsMessage = async () => {
    setIsMessagePreLoading(true);
    try {
      let requestData = {
        "conversation_id": selectedConversation,
        "message": textMessage
      }
      // const sendConversationsResponse = await sendMessage(requestData);
      setConversationMessages((prevConversations) => [
        ...prevConversations,
        { content: textMessage, created_at:new Date(),updated_at:new Date()}
      ]);

      setTextMessage("");
      setTimeout(() => {
        messageScrollToBottom();
      }, 300)

      const sendConversationsResponse = await handlePostApiCall(sendMessageUrl, requestData);

      setConversationMessages((prevConversations) => prevConversations.slice(0, -1));
      let sendConversationsMessageDataResponse = sendConversationsResponse?.data?.messages;
      setConversationMessages((prevConversations) => [
        ...prevConversations,
        ...sendConversationsMessageDataResponse
      ]);

      if (sendConversationsResponse?.data?.conversation?.title) {
        setSelectedConversationObj(prev => ({
          ...prev,
          title: sendConversationsResponse?.data?.conversation?.title
        }));
      }
      
      setTimeout(() => {
        messageScrollToBottom();
      }, 300)
      // setConversationsList(sendConversationsMessageDataResponse)
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      showToastMessage("error", error.response.data?.message);
    }finally{
      setIsMessagePreLoading(false);
    }
  };

  /* Message Scroll */

  const [messageRequestParamPage, setMessageRequestParamPage] = useState(1)
  const [messageRequestParamPageTotal, setMessageRequestParamPageTotal] = useState(0)

  useEffect(() => {
    const container = messageContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleMessageScroll);
      return () => container.removeEventListener('scroll', handleMessageScroll);
    }
  }, []);

  useEffect(() => {
    if(selectedConversation && (messageRequestParamPageTotal >= messageRequestParamPage)){
      handleScrollConversation()
    }
  }, [messageRequestParamPage]);


  const handleMessageScroll = async () =>{
    if (messageContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;  
      
      setIsShowScrollDown(scrollTop < scrollHeight-(clientHeight*3))
      
      if (scrollTop === 0 && selectedConversation !== undefined) {
        setMessageRequestParamPage(messageRequestParamPage+1);
      }
    }
  }

  const handleScrollConversation = async () => {

    try {
      let requestData = {
        "page": messageRequestParamPage,
        "per_page": 20
      }
      const getConversationMsgResponse =
        await handleGetApiCall(getConversationsUrl, requestData, selectedConversation);
      let sendConversationsMessageDataResponse = getConversationMsgResponse?.data?.messages;
      
      setConversationMessages((prevConversations) => [
        ...(Array.isArray(sendConversationsMessageDataResponse) 
        ? sendConversationsMessageDataResponse?.reverse() : 
        [sendConversationsMessageDataResponse?.reverse()]),
        ...prevConversations,
      ]);      

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      showToastMessage("error", error.response.data?.message);
    } finally {}

  }

  // Delete conversation call
  const handleDeleteConversation = async () => {
    setIsLoginPreLoading(true);
    try {
      let requestParam = {
        "conversation_id": selectedConversation
      }

      const response =
        await handleDeleteApiCall(deleteConversationsUrl, {}, requestParam);
      
      setConversationsList(conversationsList?.filter(item => item?.id !== selectedConversation))

      setSelectedConversation(null);
      setConversationMessages([])
      setIsInitialView(true)
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      showToastMessage("error", error.response.data?.message);
    } finally {
      setTimeout(() => {
        setIsLoginPreLoading(false);
      }, 100)
    }
  };

  // Update conversation title
  const handleUpdateConversationTitle = async () => {
    
  
    setIsLoginPreLoading(true);
    try {
      let requestPayload = {
        "conversation_id": selectedConversation?.toString(),
        "title": conversationTitleInput
      }

      const response =
        await handlePatchApiCall(updateConversationTitle, requestPayload, null);
      const updatedData = conversationsList?.map(item =>
        item.id === selectedConversation ? { ...item, title: conversationTitleInput } : item
      );
      setConversationsList(updatedData)

      setSelectedConversationObj(prev => ({
        ...prev,
        title: conversationTitleInput
      }));
      setIsEditTitle(false);

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      showToastMessage("error", error.response.data?.message);
    } finally {
      setTimeout(() => {
        setIsLoginPreLoading(false);
      }, 100)
    }
  };

  const attachmentOverlay = useRef(null);
  const [attachementDoc, setAttachementDoc] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setThumbnail(null)
    setPdfUrl(null)
    setPreviewUrl(null)
    setAttachementDoc(null)
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const [thumbnail, setThumbnail] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  const onFileUpload = async (event) => {
    const file = event.files[0];
    setAttachementDoc(file)
    if (file && file.type === 'application/pdf') {
      const fileUrl = URL.createObjectURL(file);
      setPdfUrl(fileUrl);

      const pdf = await pdfjsLib.getDocument(fileUrl).promise;
      const page = await pdf.getPage(1);

      const viewport = page.getViewport({ scale: 4 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({
        canvasContext: context,
        viewport: viewport,
      }).promise;

      setThumbnail(canvas.toDataURL());
      handleShow()
    }
  };
   
  const onImageUpload = async (event) => {
    const file = event.files[0];
    const previewUrl = URL.createObjectURL(file);
    setAttachementDoc(file)
    setPreviewUrl(previewUrl); 
    handleShow()
  } 

  // send conversation message
  const sendFormdataMessage = async () => {
    setIsMessagePreLoading(true);
    try {

      let requestFotmData = new FormData();
      requestFotmData.append("conversation_id", selectedConversation);
      requestFotmData.append("message", textMessage);

      if (attachementDoc) {
        requestFotmData.append("file", attachementDoc);
      }
      
      const sendConversationsResponse = await handlePostApiCall(sendMessageFormDataUrl, requestFotmData);
      let sendConversationsMessageDataResponse = sendConversationsResponse?.data?.messages;
      setConversationMessages((prevConversations) => [
        ...prevConversations,
        ...sendConversationsMessageDataResponse || []
      ]);
      setTextMessage("")
      setTimeout(() => {
        messageScrollToBottom();
      }, 300)
      // setConversationsList(sendConversationsMessageDataResponse)
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      showToastMessage("error", error.response.data?.message);
    } finally {
      handleClose();
      setTimeout(() => {
        setIsMessagePreLoading(false);
      }, 100)
      
    }
  };


  /*  */

  const imageAttachOptions = { icon: <FontAwesomeIcon icon={faImage} />, iconOnly: true, className: 'icon-button image-icon' };
  const docAttachOptions = { icon: <FontAwesomeIcon icon={faFile} />, iconOnly: true, className: 'icon-button document-icon' };
  const audioAttachOptions = { icon: <FontAwesomeIcon icon={faHeadphones} />, iconOnly: true, className: 'icon-button audio-icon' };

  /* Responsive Purpose */
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(()=>{
    setIsChatOpen(selectedConversation ? true : false)
  },[selectedConversation])

  /*  */

  const InitialView = () => {
    return (
      <Card.Body className='p-3 conversation-box initial-view'>
        <section className=''>
          <div className='inner-div'>
            <img
              src={require("../../assets/image/chatbot-icon.png")}
              alt=""
              className="chatbot-image"
            />
            <h4 className='welcome-text'>Welcome &#128075;</h4>
            <p className='start-text'>Let's have chat with our DH Ai</p>
            <p className='start-subtext'>Start converstaion with our Ai right now</p>
            <Button
              variant="contained"
              color="success"
              size="small"
              className="start-button"
              onClick={() => {
                handleSetInitialView()
              }}
            >
              Start Conversation
            </Button>
          </div>
        </section>
      </Card.Body>
    )
  }

  const ChatConversationLoader = () => {
    return (
      <div className="chat-conversation-loader">
        <img src={require("../../assets/image/preloader-2.gif")} alt="" />
      </div>
    );
  };

  const ChatMessageLoader = () => {
    return (
      <div class="message-loader">
        <div class="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };


  return (
    <div>
      <MenuBar />
      <section className='main-container'>

        <Grid container spacing={2} className='chat-bot-container'>
          <Grid item md={4} sm={4} xs={12} className={`responsive-chat-list ${isChatOpen ? "chat-close" : "chat-open"}`}>
            <section className='search-section d-flex justify-content-between align-items-center hide'>
              <TextField
                className="my-2 search-textbox"
                size={"small"}
                fullWidth
                placeholder='Search'
                type={"text"}
                name="search_ai"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon
                        icon={faSearch}
                      />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                onChange={(e) => { }}
              />
              <div className="new-chat-icon" >
                <Tooltip title="New Chat">
                  <FontAwesomeIcon
                    icon={faMessage}
                    className='ms-2'
                    onClick={() => {
                      handleSetInitialView()
                    }}
                  />
                </Tooltip>
              </div>
            </section>

            <Card className='conversation-list'>
              <Card.Header className='header d-flex justify-content-between align-items-center'>
                <span>
                  {decodedToken?.is_beta ? (
                    <a
                      href={`${langfuseBaseUrl}/users/${decodedToken?.user_uuid}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{textDecoration: "underline"}}
                    >
                      Conversations
                    </a>
                  ) : (
                    <span>Conversations</span>
                  )}
                </span>
                <div className="new-chat-icon d-flex">
                  <Tooltip title="New Chat">
                    <FontAwesomeIcon
                      icon={faMessage}
                      className='ms-2'
                      onClick={() => {
                        handleSetInitialView()
                      }}
                      style={{
                        fontSize: "14px",
                        background: "#494956",                  
                        padding: "10px",
                        color: "white",
                        borderRadius: "5px"
                      }}
                    />
                  </Tooltip>
                </div>
              </Card.Header>
              
              {conversationsList?.length ? (
                <Card.Body className='p-2 list-scroll scroll-bar-design'
                  // onScroll={handleListScroll}
                  ref={listContainerRef}
                >
                  <section>
                    {conversationsList?.map((item, index) => (
                      <Card key={index} className={`conversation-item ${item?.id === selectedConversation ? "active-chat" : ""}`}
                        ref={index === conversationsList.length - 1 ? lastItemRef : null}
                        onClick={() => {
                          handleSelectConversation(item?.id)
                        }}>
                        <Card.Body className='p-2 d-flex align-items-center justify-content-between'>
                          <div className='d-flex align-items-center'>
                            <img
                              src={require("../../assets/image/chatbot-icon.png")}
                              alt=""
                              className="chatbot-icon"
                            />
                            <div>
                              <p className='title'>
                                {item?.title
                                  ? item?.title
                                  : `Conversation - #${item?.id}`}
                              </p>
                              {/* <p className='last-message'>Last sent/Received Message</p> */}
                              {item?.created_at && (
                                <p className='timestamp'>
                                  <FontAwesomeIcon icon={faClock} className='me-1' />
                                  {formatTimestamp(item?.updated_at)}
                                </p>)}
                            </div>
                          </div>
                          <div>
                            <FontAwesomeIcon icon={faAngleRight} className='me-2' />
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  </section>
                </Card.Body>) : 
                (<InitialView />)
              }
            </Card>
          </Grid>
          <Grid item md={8} sm={8} xs={12} className={`responsive-chat-message ${isChatOpen ? "chat-open" : "chat-close"}`}>
            <Card className='conversation-view'>
              <Card.Header className='header'>
                <div className='d-flex justify-content-between align-items-center'>
                  <span className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faAngleLeft} 
                      className='responsive-chat-back-icon' 
                      onClick={() => {
                        setIsChatOpen(false);
                        setSelectedConversation(null);
                        setConversationMessages([])
                        setIsInitialView(true)
                      }} 
                    /> 
                    {}

                    {isEditTitle ? (
                      <>
                        <TextField
                          variant="standard"
                          className='title-input'
                          fullWidth
                          size='small'
                          value={conversationTitleInput}
                          onChange={(e) => {
                            setConversationTitleInput(e.target.value)
                          }}
                          onKeyUp={(event) => {
                            if (event.key === "Enter") {
                              handleUpdateConversationTitle()
                              //   event.preventDefault();
                              //   if (textMessage?.trim()) {
                              //   }
                            }
                          }} />
                          
                        <Tooltip title="Update Title">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className='ms-2'
                            onClick={() => {
                              handleUpdateConversationTitle()
                            }}
                            style={{
                              fontSize: "18px",
                              background: "green",
                              padding: "6px",
                              color: "white",
                              borderRadius: "5px"
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <span>
                         {selectedConversationObj?.title || "Conversation View -"}
                      </span>
                    )}

                    {decodedToken?.is_beta ? (
                      <a
                        href={`${langfuseBaseUrl}/sessions/${selectedConversation}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{textDecoration: "underline"}}
                      >
                        &nbsp;{"#"}{selectedConversation}
                      </a>
                    ) : (
                      <span>&nbsp;{"#"}{selectedConversation}</span>
                    )}
                  </span>
                  <Box sx={{ flexGrow: 0 }}>
                    {selectedConversation && (
                      <>
                        <Tooltip title="Open Menu">
                          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                          </IconButton>
                        </Tooltip>

                        <Menu
                          sx={{ mt: '45px' }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          <MenuItem onClick={() => {
                            handleCloseUserMenu();
                            setConversationTitleInput(selectedConversationObj?.title || "")
                            setIsEditTitle(true)
                          }}>
                            <Typography sx={{ textAlign: 'center' }}>
                              <FontAwesomeIcon icon={faPenToSquare} className='me-1' />{"Edit Title"}
                            </Typography>
                          </MenuItem>

                          <MenuItem onClick={() => {
                            
                            handleCloseUserMenu()
                            Swal.fire({
                              title: "Are you sure you want to proceed?",
                              showCancelButton: true,
                              confirmButtonText: "Yes",
                              denyButtonText: `No`,
                              icon: "warning"
                            }).then((result) => {
                              if (result.isConfirmed) {
                                handleDeleteConversation();
                              } else if (result.isDenied) {
                              }

                            });

                          }}>
                            <Typography sx={{ textAlign: 'center' }}>
                              <FontAwesomeIcon icon={faTrash} className='me-1' />{"Delete"}
                            </Typography>
                          </MenuItem>
                          <MenuItem onClick={() => {
                            setIsEditTitle(false)
                            handleCloseUserMenu()
                            setSelectedConversation(null);
                            setConversationMessages([])
                            setIsInitialView(true)
                          }}>
                            <Typography sx={{ textAlign: 'center' }}>
                              <FontAwesomeIcon icon={faClose} className='me-1' />{"Close"}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Box>
                </div>
              </Card.Header>
              {isInitialView ?
                (<InitialView />) :
                (<Card.Body className='conversation-box'>
                  <div className='conversation-inner scroll-bar-design' ref={messageContainerRef} onScroll={handleMessageScroll}>
                    {conversationMessages?.map((message, index) => (
                      <span key={index}>
                        <div className={`${message?.sender === "bot" ? "message-server" : "message-client"}`}>

                          {message?.url && (<img src={message?.url} alt="" className='message-image' />)}
                          <p>{renderText(message?.content)}</p>
                          {message?.created_at && (
                            <div className='d-flex justify-content-end align-items-center'>

                              {(message?.sender === "bot" && decodedToken?.is_beta && message?.trace_id) && (
                                <a
                                  href={`${langfuseBaseUrl}/traces/${message?.trace_id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {/* <Tooltip title={"Trace Message in a new Tab"}> */}
                                    <FontAwesomeIcon icon={faUpRightFromSquare} className='new-tab-button-icon' />
                                  {/* </Tooltip> */}
                                </a>
                              )}

                              {message?.sender === "bot" && (
                                <OverlayRating message={message}
                                />
                              )}
                              <p className='timestamp'>{formatTimestamp(message?.created_at)}
                                <FontAwesomeIcon icon={faClock} className='ms-1' />
                              </p>
                            </div>)}
                        </div>
                      </span>
                    ))}

                    {isConversationLoading && (<ChatConversationLoader />)}

                    {isMessagePreLoading && (<ChatMessageLoader />)}

                    {isShowScrollDown && (
                      <Fab 
                        size="small" 
                        color="secondary" 
                        aria-label="add" 
                        sx={{
                          position: 'sticky',
                          bottom: 0,
                          left: "calc(100% - 40px)",
                        }}
                        onClick={() => {
                          messageScrollToBottom()
                        }}>
                        <FontAwesomeIcon icon={faDownLong} />
                      </Fab>
                    )}

                  </div>
                </Card.Body>)
              }

              {!isInitialView && (
                <Card.Footer>
                  <section className='conversation-bottom-section'>
                    <TextField
                      multiline
                      className='message-area'
                      rows={2}
                      fullWidth
                      size='small'
                      placeholder="Please Enter your Question..."
                      value={textMessage}
                      onChange={(e) => {
                        setTextMessage(e.target.value)
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter" && !event.shiftKey) {
                          event.preventDefault();
                          if (textMessage?.trim()) {
                            sendConversationsMessage();
                            setTextMessage('');
                          }
                        }
                      }}
                    />

                    <IconButton
                      className='icon-button send-message'
                      onClick={() => {
                        if (textMessage?.trim()) {
                          sendConversationsMessage()
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </IconButton>
                    <IconButton
                      className='icon-button attach-icon'
                      onClick={(e) => attachmentOverlay.current.toggle(e)}
                    >
                      <FontAwesomeIcon icon={faPaperclip} />
                    </IconButton>
                    <OverlayPanel ref={attachmentOverlay}>
                      <section className='attachment-section'>
                        <FileUpload chooseOptions={docAttachOptions} mode="basic" accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, text/plain, application/rtf" maxFileSize={1000000} customUpload onSelect={onFileUpload} />
                        <FileUpload chooseOptions={imageAttachOptions} mode="basic" accept="image/*" maxFileSize={1000000} customUpload onSelect={onImageUpload} />
                        {/* <FileUpload chooseOptions={audioAttachOptions} mode="basic" accept="audio/*" maxFileSize={1000000} customUpload onSelect={onUpload} /> */}
                      </section>
                    </OverlayPanel>

                  </section>
                </Card.Footer>
              )}
            </Card>
          </Grid>
        </Grid>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='upload-picture'>
              
              {previewUrl && (
                <img 
                  className='uploaded-image'
                  src={previewUrl} 
                  alt="upload thumbnail" 
                />
              )}
              <div className='text-center'>
                {pdfUrl && (
                  <img
                    className='pdf-image'
                    src={thumbnail}
                    alt="PDF Thumbnail"
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open(pdfUrl, '_blank')}
                  />
                )}
              </div>
              <TextField
                multiline
                className='message-area mt-2'
                rows={2}
                fullWidth
                size='small'
                placeholder="Please Enter your Question..."
                value={textMessage}
                onChange={(e) => {
                  setTextMessage(e.target.value)
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose} disabled={isMessagePreLoading}>
              Close
            </Button>
            <Button variant="primary" disabled={isMessagePreLoading} onClick={() => {
              sendFormdataMessage()
            }}>
              Send File
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </div>
  )
}

export default Home