import React, { createContext, useEffect, useRef, useState } from "react";
import { Toast } from 'primereact/toast';
import { Player } from '@lottiefiles/react-lottie-player';
import chatBotLoaderJson from '../assets/lottie/ChatBot-Loader.json';
import { getConversationsList } from "../api/AuthApi";
import Cookies from "universal-cookie";
import { handleGetApiCall } from "../api/CommonApiCall";
import { getConversationsListUrl } from "../constant/UrlList";
import { jwtDecode } from "jwt-decode";

const MainContext = createContext({});

export const MainContextProvider = ({ children }) => {

    const toast = useRef(null);
    const cookies = new Cookies();

    const [accessToken, setAccessToken] = useState(null);
    const [decodedToken, setDecodedToken] = useState(null);
    const [langfuseBaseUrl, setLangfuseBaseUrl] = useState(null);
    const [isLoginPreLoading, setIsLoginPreLoading] = useState(false);

    const [selectedConversation, setSelectedConversation] = useState(null)
    const [conversationsList, setConversationsList] = useState([])
    const [conversationMessages, setConversationMessages] = React.useState([])
    
    const [conversationPageNo, setConversationPageNo] = React.useState(1)
    const prevPageNoRef = useRef();

    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);

    const [conversationParam, setConversationParam] = React.useState({
        page: 1,
        per_page: 20
    })

    const showToastMessage = (summary, detail) => {
        toast.current.show({
            severity: summary,
            summary,
            detail,
            life: 3000,
        });
    };

    const PreloaderLottie = () => {
        return (
            <div className="chat-bot-preloader">
                <Player
                    autoplay
                    loop
                    src={chatBotLoaderJson}
                    speed={1.5}
                    style={{ height: '300px', width: '300px' }}
                />
            </div>
        );
    };

    //Access Token Setting
    useEffect(() => {
        if (new Cookies().get("access_token")) {
            setAccessToken(new Cookies().get("access_token"));
        }
    }, []);

    useEffect(() => {
        if (accessToken) {
            getConversationsListCall(true);
            setTimeout(()=>{
                setHasMore(true);
            })
        }
    }, [accessToken])

    /* Decode */
    useEffect(() => {
        if (accessToken) {
            let decodetoken = jwtDecode(accessToken);
            setDecodedToken(decodetoken)
            setLangfuseBaseUrl(decodetoken?.langfuse_baseurl)
        }
    }, [accessToken])

    // Get Conversation common
    const getConversationsListCall = async (initCall = true) => {
        setLoading(true);

        try {
            let requestData = {
                page: conversationPageNo,
                per_page: 40
            }
            // const getConversationsListDataResponse =
            //     await getConversationsList(requestData);
            const getConversationsListDataResponse =
                await handleGetApiCall(getConversationsListUrl, requestData);

            if (getConversationsListDataResponse?.data?.conversations?.length === 0) {
                setHasMore(false);
                setConversationPageNo((prevPage) => prevPage - 1);
            } else {                
                // setConversationsList((prevList) => 
                //     initCall 
                //         ? getConversationsListDataResponse?.data?.conversations || [] 
                //         : [...prevList, ...(getConversationsListDataResponse?.data?.conversations || [])]
                //     );

                setConversationsList((prevList) => {
                    const newConversations = getConversationsListDataResponse?.data?.conversations || [];
                
                    if (initCall) {
                        return newConversations;
                    }                    
                    const combinedList = [...prevList, ...newConversations];                    
                    const uniqueConversations = Array.from(
                        new Map(combinedList.map(conversation => [conversation.id, conversation])).values()
                    );                    
                    return uniqueConversations;
                });
                    
            }

            
        } catch (error) {
            console.error(
                "Error fetching data:",
                error?.response?.data
            );
            // showToastMessage("error", error?.response?.data?.message);
            showToastMessage("error", "Session Expired. Please Login Again");
            cookies.remove("access_token");

            setTimeout(() => {
                window.location.reload();
            }, 300)
            
        } finally {
            setLoading(false);
        }
    };  

    useEffect(() => {
        if(accessToken){
            // console.log(prevPageNoRef.current, conversationPageNo)
            if(prevPageNoRef.current && ( prevPageNoRef.current > conversationPageNo)){
                return;
            }
            prevPageNoRef.current = conversationPageNo;

            getConversationsListCall(false);
        }
    }, [conversationPageNo]);    

    return (
        <MainContext.Provider
            value={{
                showToastMessage,
                accessToken, setAccessToken,
                isLoginPreLoading, setIsLoginPreLoading,
                selectedConversation, setSelectedConversation,
                conversationsList, setConversationsList,
                conversationMessages, setConversationMessages,
                getConversationsListCall,
                conversationParam, setConversationParam,

                conversationPageNo, setConversationPageNo,
                hasMore, setHasMore,
                loading, setLoading,

                decodedToken, setDecodedToken,
                langfuseBaseUrl, setLangfuseBaseUrl
            }}
        >
            <Toast ref={toast} />
            {isLoginPreLoading && <PreloaderLottie />}
            {children}
        </MainContext.Provider>
    );
};

export default MainContext;